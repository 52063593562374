<template>
  <div>
    <BaseTable
      ref="orders-table"
      :columns="columns"
      :filters="baseFilters"
      :totals-configuration="totalsConfiguration"
      :resource="$data.$constants.RESOURCES.RESOURCE_ORDERS"
      :resourceStore="'orders'"
      :loading="loading"
      :excluded-filters="baseExcludeFilters"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearOfferListFilters"
      @row-clicked="$router.push({ name: 'viewOrder', params: { id: $event.id } })"
    >
      <template #cell(job)="data">
        <span> {{ data.item.job.code }} </span>
      </template>
      <template #cell(order_date)="data">
        {{ new Date(data.item.order_date).toLocaleDateString() }}
      </template>
      <template #cell(provider)="data">
        <span> {{ data.item.provider.name }} </span>
      </template>
      <template #cell(pending_billing)="{ value }">
        <div> {{ value | numberToLocalString }} €</div>
      </template>
      <template #cell(total_gross)="{ value }">
        <div> {{ value | numberToLocalString }} €</div>
      </template>
      <template #cell(total_order)="{ value }">
        <div> {{ value | numberToLocalString }} €</div>
      </template>
      <template #cell(status)="data">
        <StatusBadge
          :status="data.item.status.name"
          :text="getStatusText(data.item)"
        />
      </template>
      <template #cell(status_notes)="data">
        <StatusBadge
          :status="data.item.status_notes.name"
        />
      </template>
      <template #cell(actions)="data">
        <feather-icon
            v-show="data.item.pm_comments"
            :id="`popover-target-${data.item.id}`"
            icon="InfoIcon"
            size="18"
            class="text-indigo mr-1"
        />
        <b-popover :target="`popover-target-${data.item.id}`" triggers="hover" placement="top">
          <template #title>Anotaciones PM</template>
          <pre style="background: #ffff;">{{ data.item.pm_comments }}</pre>
        </b-popover>
        <b-link class="d-inline-block text-indigo">
          <feather-icon
            v-b-tooltip.hover 
            title="Descargar pedido"
            icon="DownloadIcon"
            size="18"
            @click.stop="handlePrintLinkClick(data.item.id)"
          />
        </b-link>
        <b-link
          :to="{ name: 'viewOrder', params: { id: data.item.id} }"
          class="px-1 d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_ORDERS,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="handleDeleteIconClick(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </template>
    </BaseTable>
    <OrdersListFilters
      ref="orders-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('orders/setFilters', $event)"
    />
  </div>
</template>

<script>
import OrdersApi from '@/api/orders-api'
import { STATUS_BADGE_PARTIAL_INVOICED } from '@/shared/constants/statusBadges'
import OrdersListFilters from '@/components/orders/filters/OrdersListFilters.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OrdersTable',
  components: { BaseTable, StatusBadge, OrdersListFilters },
  props: {
    providerId: {
      type: Number,
      default: null,
    },
    totalsConfiguration: {
      type: Array,
      default: () => [
        { colspan: 7 },
        { label: 'TOTAL PNDT. DE FACT', key: 'total_pending_billing', unit: '€' },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL PEDIDO', key: 'total_order', unit: '€' },
        { colspan: 1 },
      ],
    },
  },
  data() {
    return {
      loading: false,
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'orders/getFilters',
    }),
    baseExcludeFilters() {
      const excludedFilters = []
      if (this.providerId) {
        excludedFilters.push('provider_id')
      }

      return excludedFilters
    },
    baseFilters() {
      const filters = { ...this.filters }
      if (this.providerId) {  
        filters.provider_id = this.providerId
      }
      return filters
    },
    columns() {
      const items = [
        {
          label: 'REF JOB',
          key: 'job',
          sortable: true,
          thStyle: { 'min-width': '100px' },
        },
        {
          label: 'Nº PEDIDO',
          key: 'number',
          sortable: true,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'FECHA PEDIDO',
          key: 'order_date',
          sortable: true,
        },
      ]

      if (!this.providerId) {
        items.push({
          label: 'PROVEEDOR',
          key: 'provider',
          sortable: true,
        })
      }

      return items.concat([
        {
          label: 'ESTADO FACTURACIÓN',
          key: 'status',
          sortable: true,
        },
        {
          label: 'ESTADO ALB.',
          key: 'status_notes',
          sortable: true,
        },
        {
          label: 'PNDT. DE FACT.',
          key: 'pending_billing',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '140px' },
        },
        {
          label: 'T.BRUTO',
          key: 'total_gross',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '110px' },

        },
        {
          label: 'TOTAL',
          key: 'total_order',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
          thStyle: { 'min-width': '110px' },
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '210px' },
        },
      ])
    },
  },
  methods: {
    handleClearOfferListFilters() {
      this.$store.commit('orders/setFilters', null)
      this.$refs['orders-list-filters'].clearFilters()
    },
    getStatusText(order) {
      if (order.status?.name.toLowerCase() !== STATUS_BADGE_PARTIAL_INVOICED.status) {
        return order.status.name
      }

      return `${order.status.name} ${order.billed_percentage} %`
    },
    async handlePrintLinkClick(orderId) {
      this.loading = true
      try {
        await OrdersApi.printOrder(orderId)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(order) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${order.number}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await OrdersApi.delete(order.id)
        await this.$refs['orders-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
